import React from 'react';
import './Ratings.css';

const Ratings = () => {
    return (
        <>
        <section class="section-sp1 service-wraper2">
            <div class="container">
                <div class="row">
                    <div class="col-xl-3 col-sm-6 mb-30">
                        <div class="feature-container feature-bx3">
                            <h2 class="counter text-secondary">
                                120
                            </h2>
                            <h5 class="ttr-title">
                                Years With You
                            </h5>
                            <p>
                                Etiam ante ante, molestie vitae cursus ac, pharetra euismod libero.
                            </p>
                        </div>
                    </div>
                    <div class="col-xl-3 col-sm-6 mb-30">
                        <div class="feature-container feature-bx3">
                            <h2 class="counter text-secondary">
                                400
                            </h2>
                            <h5 class="ttr-title">
                                Awards
                            </h5>
                            <p>
                                Etiam ante ante, molestie vitae cursus ac,
                                pharetra euismod libero.
                            </p>
                        </div>
                    </div>
                    <div class="col-xl-3 col-sm-6 mb-30">
                        <div class="feature-container feature-bx3">
                            <h2 class="counter text-secondary">
                                250
                            </h2>
                            <h5 class="ttr-title">
                                Doctors
                            </h5>
                            <p>
                                Etiam ante ante, molestie vitae cursus ac, pharetra euismod libero.
                            </p>
                        </div>
                    </div>
                    <div class="col-xl-3 col-sm-6 mb-30">
                        <div class="feature-container feature-bx3">
                            <h2 class="counter text-secondary">
                                800
                            </h2>
                            <h5 class="ttr-title">
                                Satisfied Client
                            </h5>
                            <p>
                                Etiam ante ante, molestie vitae cursus ac, pharetra euismod libero.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}

export default Ratings;